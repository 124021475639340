import {
  ComponentInternalInstance,
  onMounted,
  onUnmounted,
  Ref,
  watch,
  WatchStopHandle,
} from "vue";
import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import { useMsal } from "./useMsal";
import { homeAccountId } from "@/utils/devutils";
import {
  AccessToken,
  getAccessTokenEffectiveRemainingDuration,
  getAccessTokenRemainingDuration,
  isNearExpiry,
} from "@/plugins/apiHelpers";
import { useGlobalLock } from "./useGlobalLock";
import { doWhenOnline } from "@/utils/onlineHelper";
import { hasAllScopes } from "@/utils/oauth/oauthHelper";

export interface ApiContext {
  vueComponentInstance: ComponentInternalInstance;
  oauthScopes: string[];
  accessToken: Ref<AccessToken | undefined>;
  accessTokenLock: Ref<boolean>;
  lockName: string;
  tokenValidSetter: (v: boolean) => void;
}

function needsMoreOauthScopes(
  accessToken: Ref<AccessToken | undefined>,
  oauthScopes: string[],
) {
  if (!accessToken.value) {
    throw new Error("Not sure if that is possible");
  } else {
    return !hasAllScopes(accessToken.value.scopes, ...oauthScopes);
  }
}

function shouldRenew(context: ApiContext) {
  return (
    !context.accessToken.value ||
    isNearExpiry(context.accessToken.value) ||
    needsMoreOauthScopes(context.accessToken, context.oauthScopes)
  );
}

async function updateAccessTokensMaybe(
  context: ApiContext,
  instance: PublicClientApplication,
  accounts: Ref<AccountInfo[]>,
) {
  log(context, "checking lock");

  // This lock prevents two (or more) mounted components to fetch access keys at the same time.
  const lock = useGlobalLock(context.vueComponentInstance, context.lockName);
  if (shouldRenew(context)) {
    await lock.doExclusively(async () => {
      log(context, "Try acquire token");
      if (accounts.value.length == 0) {
        await instance.acquireTokenRedirect({
          scopes: context.oauthScopes,
        });
      } else {
        try {
          const at = await instance.acquireTokenSilent({
            scopes: context.oauthScopes,
            forceRefresh: true,
          });
          //at.expiresOn = DateTime.now().plus({second: 40}).toJSDate();
          context.accessToken.value = at;

          homeAccountId.value =
            accounts.value.length > 0
              ? accounts.value[0].homeAccountId
              : undefined;

          context.tokenValidSetter(true);
        } catch (e) {
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              scopes: context.oauthScopes,
            });
          }
          throw e;
        }
      }
    });
  } else {
    log(context, "already valid");
  }
}

function registerRefresh(
  context: ApiContext,
  instance: PublicClientApplication,
  accounts: Ref<AccountInfo[]>,
) {
  const internalInstance = context.vueComponentInstance;
  log(context, "registering refresh");
  const doUpdate = async () =>
    updateAccessTokensMaybe(
      context,
      instance,
      accounts,
    );

  let timerRef: number | undefined;
  let onlineWaitCancel: () => void | undefined;
  let unwatch: WatchStopHandle;
  const registerRefreshInternal = () => {
    // Ensure that after we get the first access token, we fetch a new one before it expires.
    // So wait until we even have a token...
    unwatch = watch(
      context.accessToken,
      (at) => {
        log(context, "AT changed" + (at ? "" : ": undefined"));
        if (at) {
          log(context, "AT is not null, set timer for refresh");
          // ...when we do then set a timer based on the expiration...
          timerRef = window.setTimeout(() => {
            const actual = getAccessTokenRemainingDuration(at);
            const effective = getAccessTokenEffectiveRemainingDuration(at);
            log(
              context,
              `AT near expiration (actual: ${actual}, effective: ${effective}), refreshing...`,
            );

            // ...do it when we are online (now or later).
            const { cancel } = doWhenOnline(async () => {
              log(context, "online!");
              await doUpdate();
            });
            onlineWaitCancel = cancel;
          }, getAccessTokenEffectiveRemainingDuration(at).toMillis());
        }
      },
      { immediate: true },
    );
  };

  if (internalInstance.isMounted) {
    registerRefreshInternal();
  }
  onMounted(registerRefreshInternal, internalInstance);

  onUnmounted(() => {
    if (timerRef) {
      log(context, "ApiTokens: Removing timer");
      clearTimeout(timerRef);
    }
    if (onlineWaitCancel) {
      onlineWaitCancel();
    }
    log(context, "unwatch registerRefreshInternal");
    unwatch?.();
  }, internalInstance);
}

export async function useBackendApiBase(context: ApiContext): Promise<void> {
  const { instance, interactionStatus, accounts, addListener, removeListener } =
    useMsal(context.vueComponentInstance);

  if (context.vueComponentInstance.isMounted) {
    addListener(context.vueComponentInstance.uid, () =>
      updateAccessTokensMaybe(context, instance, accounts),
    );
  }
  onMounted(() => {
    addListener(context.vueComponentInstance.uid, () =>
      updateAccessTokensMaybe(context, instance, accounts),
    );
  }, context.vueComponentInstance);

  if (interactionStatus.value !== InteractionStatus.HandleRedirect) {
    await updateAccessTokensMaybe(
      context,
      instance,
      accounts,
    );
  }

  registerRefresh(context, instance, accounts);

  onUnmounted(() => {
    removeListener(context.vueComponentInstance.uid);
  }, context.vueComponentInstance);
}

function log(context: ApiContext, m: string) {
  console.debug(
    "AccessTokens: " +
      context.lockName +
      ": " +
      context.vueComponentInstance?.uid +
      ": " +
      m,
  );
}

declare module "vue" {}
