/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbbgRechnungsPositionDefinition
 */
export interface DbbgRechnungsPositionDefinition {
    /**
     * 
     * @type {number}
     * @memberof DbbgRechnungsPositionDefinition
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DbbgRechnungsPositionDefinition
     */
    positionsnummer: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRechnungsPositionDefinition
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRechnungsPositionDefinition
     */
    einheit: string;
    /**
     * 
     * @type {number}
     * @memberof DbbgRechnungsPositionDefinition
     */
    preis: number;
    /**
     * 
     * @type {string}
     * @memberof DbbgRechnungsPositionDefinition
     */
    gltigVon: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRechnungsPositionDefinition
     */
    gltigBis: string;
    /**
     * 
     * @type {string}
     * @memberof DbbgRechnungsPositionDefinition
     */
    typ: DbbgRechnungsPositionDefinitionTypEnum;
    /**
     * Falls vom Typ "ZEIT_*", gibt an, ob die Schicht ausgefallen ist.
     * @type {boolean}
     * @memberof DbbgRechnungsPositionDefinition
     */
    ausfall?: boolean;
    /**
     * Falls vom Typ "ZEIT_*", gibt an, ab wann am Tag der Geltungsbereich beginnt. Nur interessant für "Nacht" order "Samstag".
     * @type {string}
     * @memberof DbbgRechnungsPositionDefinition
     */
    zeitVon?: string;
    /**
     * Falls vom Typ "ZEIT_*", gibt an, ab wann am Tag der Geltungsbereich endet. Nur interessant für "Samstag".
     * @type {string}
     * @memberof DbbgRechnungsPositionDefinition
     */
    zeitBis?: string;
    /**
     * Falls vom Typ "TRANSPORT_*", gibt an, ab wie vielen Kilometern der Geltungsbereich beginnt.
     * @type {number}
     * @memberof DbbgRechnungsPositionDefinition
     */
    kmVon?: number;
    /**
     * Falls vom Typ "TRANSPORT_*", gibt an, ab wie vielen Kilometern der Geltungsbereich endet.
     * @type {number}
     * @memberof DbbgRechnungsPositionDefinition
     */
    kmBis?: number;
    /**
     * Falls vom Typ "NACHLASS", werden die Anzahl der Schichten angegeben, für die ein Nachlass gilt (3, 4, ..., >=7).
     * @type {number}
     * @memberof DbbgRechnungsPositionDefinition
     */
    anzahlSchichten?: number;
}


/**
 * @export
 */
export const DbbgRechnungsPositionDefinitionTypEnum = {
    ZeitTag: 'ZEIT_TAG',
    ZeitNacht: 'ZEIT_NACHT',
    ZeitSamstag: 'ZEIT_SAMSTAG',
    ZeitSonntag: 'ZEIT_SONNTAG',
    ZeitFeiertag: 'ZEIT_FEIERTAG',
    TransportSelbst: 'TRANSPORT_SELBST',
    TransportExtern: 'TRANSPORT_EXTERN',
    TransportPersonal: 'TRANSPORT_PERSONAL',
    Nachlass: 'NACHLASS'
} as const;
export type DbbgRechnungsPositionDefinitionTypEnum = typeof DbbgRechnungsPositionDefinitionTypEnum[keyof typeof DbbgRechnungsPositionDefinitionTypEnum];


/**
 * Check if a given object implements the DbbgRechnungsPositionDefinition interface.
 */
export function instanceOfDbbgRechnungsPositionDefinition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "positionsnummer" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "einheit" in value;
    isInstance = isInstance && "preis" in value;
    isInstance = isInstance && "gltigVon" in value;
    isInstance = isInstance && "gltigBis" in value;
    isInstance = isInstance && "typ" in value;

    return isInstance;
}

export function DbbgRechnungsPositionDefinitionFromJSON(json: any): DbbgRechnungsPositionDefinition {
    return DbbgRechnungsPositionDefinitionFromJSONTyped(json, false);
}

export function DbbgRechnungsPositionDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbbgRechnungsPositionDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'positionsnummer': json['Positionsnummer'],
        'name': json['Name'],
        'einheit': json['Einheit'],
        'preis': json['Preis'],
        'gltigVon': json['GültigVon'],
        'gltigBis': json['GültigBis'],
        'typ': json['Typ'],
        'ausfall': !exists(json, 'Ausfall') ? undefined : json['Ausfall'],
        'zeitVon': !exists(json, 'ZeitVon') ? undefined : json['ZeitVon'],
        'zeitBis': !exists(json, 'ZeitBis') ? undefined : json['ZeitBis'],
        'kmVon': !exists(json, 'KmVon') ? undefined : json['KmVon'],
        'kmBis': !exists(json, 'KmBis') ? undefined : json['KmBis'],
        'anzahlSchichten': !exists(json, 'AnzahlSchichten') ? undefined : json['AnzahlSchichten'],
    };
}

export function DbbgRechnungsPositionDefinitionToJSON(value?: DbbgRechnungsPositionDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'Positionsnummer': value.positionsnummer,
        'Name': value.name,
        'Einheit': value.einheit,
        'Preis': value.preis,
        'GültigVon': value.gltigVon,
        'GültigBis': value.gltigBis,
        'Typ': value.typ,
        'Ausfall': value.ausfall,
        'ZeitVon': value.zeitVon,
        'ZeitBis': value.zeitBis,
        'KmVon': value.kmVon,
        'KmBis': value.kmBis,
        'AnzahlSchichten': value.anzahlSchichten,
    };
}

